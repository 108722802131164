import i18n from "@/plugins/i18n"
import router from "@/router"
import authService from "@/utils/auth/auth.service"
import type { AxiosRequestConfig } from "axios"
import { useAppStore } from "@/store/app"

export function setParamLang(config: AxiosRequestConfig) {
	let params = config.params
	if (params) {
		if (params.has("lang")) params.delete("lang")
		params.append("lang", i18n.global.locale)
	} else {
		params = new URLSearchParams({ lang: i18n.global.locale })
	}
	config.params = params
	return config
}

export async function checkAuthorizationToken(config: AxiosRequestConfig) {
	const token = await authService.getValidAccessToken()
	if (token) {
		config.headers = { ...config.headers, Authorization: `Bearer ${token}` }
		return config
	} else {
		if (!(config as any).NO_REDIRECT) {
			router.push({ name: "Auth" })
		}
		return false
	}
}

export function cancelRequest(timeoutMs = 0, abortController = new AbortController()): AbortSignal {
	setTimeout(() => abortController.abort(), timeoutMs)
	return abortController.signal
}

export function snackbar(payload: any) {
	let snackbarMessage = i18n.global.t("snackbar.message_default")
	if (payload.response.data && payload.response.status !== 500) {
		const errorsArr = payload.response.data.errors
		if (errorsArr && errorsArr.length > 0) {
			snackbarMessage = ""
			errorsArr.forEach(({ field, message }: Record<string, string>) => {
				snackbarMessage += `<strong>${field}:</strong> ${message}\n`
			})
		}
	}
	const appStore = useAppStore()
	appStore.setSnackbarData(snackbarMessage, "warning", 10000)
}
