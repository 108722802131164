// Utilities
import { defineStore } from "pinia"
import i18n from "@/plugins/i18n"
import { mdiAlertOutline, mdiArchiveOutline, mdiGarage, mdiScrewLag, mdiTableCheck, mdiTableClock } from "@mdi/js"

type appState = {
	isRouterLoading: boolean
	isContentLoading: boolean
	isSwitchingLanguage: boolean
	showMenus: boolean
	width: number
	height: number
	mobileBreakpoint: number
	snackbar: boolean
	snackbarMessage: string
	snackbarType: string
	snackbarTimeout: number
	snackbarTimeoutID: NodeJS.Timeout | undefined
	pages: {
		[key: string]: {
			title: string
			icon: string
			routeName: string
			disabled?: boolean
		}
	}
}
export const useAppStore = defineStore("app", {
	state: (): appState => ({
		isRouterLoading: true,
		isContentLoading: false,
		isSwitchingLanguage: false,
		showMenus: true,
		width: 0,
		height: 0,
		mobileBreakpoint: 600,
		snackbar: false,
		snackbarMessage: "",
		snackbarType: "",
		snackbarTimeout: 4000,
		snackbarTimeoutID: undefined,
		pages: {
			turningShop: {
				title: i18n.global.t("pages.turning_shop.title"),
				icon: mdiScrewLag,
				routeName: "TurningShop"
			},
			depotPark: {
				title: i18n.global.t("pages.depot_park.title"),
				icon: mdiGarage,
				routeName: "DepotPark"
			},
			unscheduledMaintenance: {
				title: i18n.global.t("pages.unscheduled_maintenance.title"),
				icon: mdiAlertOutline,
				routeName: "UnscheduledMaintenance"
			},
			maintenancePlan: {
				title: i18n.global.t("pages.maintenance_plan.title"),
				icon: mdiTableClock,
				routeName: "MaintenancePlan"
			},
			maintenanceFact: {
				title: i18n.global.t("pages.maintenance_fact.title"),
				icon: mdiTableCheck,
				routeName: "MaintenanceFact"
			},
			archive: {
				title: i18n.global.t("pages.archive.title"),
				icon: mdiArchiveOutline,
				routeName: "Archive"
			}
		}
	}),

	getters: {
		smallScreen: (state: appState) => state.width < state.mobileBreakpoint,
		contentHeight: (state: appState) => state.height - (state.width > 960 ? 64 : 56),
		isReady: (state: appState) => !state.isContentLoading && !state.isRouterLoading,
		getMobileBreakpoint: (state: appState) => state.mobileBreakpoint
	},
	actions: {
		reset() {
			this.$reset()
		},
		router(value: boolean): void {
			this.isRouterLoading = value
			this.showMenus = !value
		},
		onResize() {
			if (typeof window === "undefined") return
			this.width = window.innerWidth
			this.height = window.innerHeight
		},
		snackbarReset() {
			if (this.snackbarMessage || this.snackbarType) {
				this.snackbar = false
				this.snackbarMessage = ""
				this.snackbarType = ""
			}
		},
		snackbarHide() {
			if (this.snackbar && (this.snackbarMessage || this.snackbarType)) {
				this.snackbar = false
			}
		},
		async snackbarResetWithDelay() {
			clearTimeout(this.snackbarTimeoutID)
			this.snackbarHide()
			setTimeout(async () => {
				this.snackbarReset()
			}, 100)
		},
		routerLoading(value: boolean) {
			this.isRouterLoading = value
			this.showMenus = !value
		},
		setSnackbar(value: boolean) {
			if (value) {
				this.snackbar = value
			} else {
				this.snackbarResetWithDelay()
			}
		},
		setSnackbarData(message: string = i18n.global.t("snackbar.message_default"), type = "info", timeout = 4000) {
			clearTimeout(this.snackbarTimeoutID)
			this.snackbar = true
			this.snackbarMessage = message
			this.snackbarType = type
			this.snackbarTimeout = timeout
			this.snackbarTimeoutID = setTimeout(async () => {
				await this.snackbarResetWithDelay()
			}, timeout)
		}
	}
})
