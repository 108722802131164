<script setup lang="ts">
import { useTheme } from "vuetify"
import { mdiWeatherNight, mdiWhiteBalanceSunny, mdiThemeLightDark } from "@mdi/js"
import { computed, onMounted, onUnmounted, ref, watch } from "vue"

const theme = useTheme()

type TypeTheme = "light" | "dark" | "system"
type TypeSystemTheme = "light" | "dark"

const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
const systemTheme = ref<TypeSystemTheme>("light")
const selectedTheme = ref<TypeTheme>("system")
const icons: Record<string, string> = {
	light: mdiWhiteBalanceSunny,
	dark: mdiWeatherNight,
	system: mdiThemeLightDark
}

const icon = computed(() => icons[selectedTheme.value])
const title = computed(() => {
	const themeMap: Record<string, string> = {
		light: "Светлая тема",
		dark: "Темная тема",
		system: "Системная тема"
	}
	return themeMap[selectedTheme.value]
})

const onClick = () => {
	let newVal: TypeTheme
	switch (selectedTheme.value) {
		case "light":
			newVal = "dark"
			break
		case "dark":
			newVal = "system"
			break
		default:
			newVal = "light"
	}
	selectedTheme.value = newVal
	setStorageTheme(newVal)
}
const getStorageTheme = () => {
	const storageVal = localStorage.getItem("user-theme")
	switch (storageVal) {
		case "light":
		case "dark":
			return storageVal
		default:
			return "system"
	}
}
const setStorageTheme = (val: string) => {
	localStorage.setItem("user-theme", val)
}
const updateSystemTheme = (e: MediaQueryListEvent | MediaQueryList) =>
	(systemTheme.value = e.matches ? "dark" : "light")

onMounted(() => {
	mediaQuery.addEventListener("change", updateSystemTheme)
})
onUnmounted(() => {
	mediaQuery.removeEventListener("change", updateSystemTheme)
})
const getEffectiveTheme = () => {
	switch (selectedTheme.value) {
		case "light":
			return "light"
		case "dark":
			return "dark"
		default:
			return systemTheme.value
	}
}
const effectiveTheme = computed(getEffectiveTheme)
watch(effectiveTheme, (val) => {
	theme.global.name.value = val
})
const initTheme = () => {
	selectedTheme.value = getStorageTheme()
	updateSystemTheme(mediaQuery)
	theme.global.name.value = getEffectiveTheme()
}
initTheme()
</script>

<template>
	<v-btn :icon="true" :title="title" @click="onClick">
		<v-icon>{{ icon }}</v-icon>
	</v-btn>
</template>
