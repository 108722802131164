import axios, { type InternalAxiosRequestConfig } from "axios"
import authService from "@/utils/auth/auth.service"
import { captureException, captureMessage } from "@sentry/vue"
import { cancelRequest, snackbar } from "@/api/functions"

const ApiSecure = axios.create({
	baseURL: import.meta.env.VITE_API_URL
})
/**
 * This is a workaround for the handling multiple requests at the same time
 * The reference to the refresh token promise is stored and then resolved for all of the parallel requests
 */
let refreshTokenPromise: Promise<string | null> | undefined = undefined
let controller: AbortController | undefined = undefined
ApiSecure.interceptors.request.use(
	async (config: InternalAxiosRequestConfig) => {
		if (!refreshTokenPromise) {
			controller = new AbortController()
			refreshTokenPromise = authService.getValidAccessToken().then((token) => {
				refreshTokenPromise = undefined
				controller = undefined
				return token
			})
		}

		return refreshTokenPromise.then((token) => {
			if (token) {
				// config.signal = cancelRequest(10000) logout is not needed when server is not responsive??
				config.headers.Authorization = `Bearer ${token}`
			} else {
				config.signal = cancelRequest(0, controller)
				controller?.abort()
			}
			return config
		})
	},
	function (payload) {
		captureMessage("api_secure:request_interceptor - rejected")
		captureException(payload)
		refreshTokenPromise = undefined
	}
)

ApiSecure.interceptors.response.use(
	async function (response) {
		if (response.status === 200 || response.status === 201) return response.data
		return null
	},
	async function (payload) {
		captureMessage("api_secure:response_interceptor - rejected")
		captureException(payload)
		if (axios.isCancel(payload) || payload.response?.status === 401) {
			authService.logout(!payload.config.NO_REDIRECT)
		}
		if (!payload.config.SILENT) snackbar(payload)
	}
)

export default ApiSecure
