import { createPinia } from "pinia"
import { PiniaLogger } from "pinia-logger"

const pinia = createPinia()
pinia.use(
	PiniaLogger({
		expanded: false,
		disabled: false
	})
)

export default pinia
