<script setup lang="ts">
import { useAppStore } from "@/store/app"
const appStore = useAppStore()
import { defineModel } from "vue"
import { useDisplay } from "vuetify"
const display = useDisplay()
const hideDrawer = display.smAndDown
const drawer = defineModel<boolean>()
</script>

<template>
	<v-navigation-drawer v-model="drawer" :expand-on-hover="!hideDrawer" :rail="!hideDrawer" :mobile-breakpoint="'md'">
		<v-list :nav="true">
			<v-list-item
				v-for="page in appStore.pages"
				:key="page.routeName"
				:prepend-icon="page.icon"
				:value="page.routeName"
				:active="$route.name === page.routeName"
				:disabled="page.disabled"
				@click="$router.push({ name: page.routeName })"
			>
				<v-list-item-title :title="page.title">{{ page.title }}</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<style scoped lang="scss"></style>
