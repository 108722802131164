/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from "@/plugins/vuetify"
import pinia from "@/store"
import router from "@/router"
import i18n from "@/plugins/i18n"
import initSentry from "@/plugins/sentry"

// Types
import type { App } from "vue"

export function registerPlugins(app: App) {
	app.use(vuetify).use(router).use(pinia).use(i18n)
	initSentry(app, router)
}
