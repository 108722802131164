<script lang="ts" setup>
import { computed, nextTick, onMounted, ref } from "vue"
import { useAppStore } from "@/store/app"
import { mdiLogout, mdiCalendar, mdiAccountCircleOutline } from "@mdi/js"
import ErrorSnackbar from "@/components/ErrorSnackbar.vue"
import { useUserStore } from "@/store/user"
import { useRoute } from "vue-router"
import MenuUser from "@/components/Menus/MenuUser.vue"
import authService from "@/utils/auth/auth.service"
import DrawerNavigation from "@/components/Drawers/DrawerNavigation.vue"
import { useDisplay, useTheme } from "vuetify"
import BtnToggleTheme from "@/components/Inputs/BtnToggleTheme.vue"
import logoDark from "@/utils/images/logo/logo_w.png"
import logoLight from "@/utils/images/logo/logo_c.png"

const route = useRoute()
const appStore = useAppStore()
const userStore = useUserStore()
const display = useDisplay()
const theme = useTheme()

const icons: Record<string, string> = {
	logout: mdiLogout,
	calendar: mdiCalendar,
	account: mdiAccountCircleOutline
}

const drawer = ref(false)

const logo = computed(() => (theme.global.current.value.dark ? logoDark : logoLight))
const notAuthPage = computed(() => route.name !== "Auth")
const isLoggedIn = computed(() => userStore.isLoggedIn)
const notMainPage = computed(() => route.name !== "Main")
const showNavMenuBtn = computed(() => display.smAndDown.value && route.name !== "Main")

onMounted(() => {
	onResize()
})
function onResize() {
	nextTick(() => {
		appStore.onResize()
		drawer.value = display.mdAndUp.value
	})
}

const logout = () => {
	authService.logout()
}
</script>

<template>
	<v-app v-resize="onResize">
		<v-app-bar
			density="compact"
			:color="notAuthPage ? undefined : 'transparent'"
			elevation="0"
			:class="{
				app_bar: notAuthPage
			}"
		>
			<template v-if="notAuthPage && isLoggedIn">
				<v-app-bar-nav-icon v-if="showNavMenuBtn" variant="text" @click="drawer = !drawer" />
				<router-link :to="{ name: 'Main' }" style="padding: 0 10px">
					<v-img alt="ВСМ-Сервис" :src="logo" width="100" :cover="true" />
				</router-link>
			</template>
			<template #append>
				<BtnToggleTheme />
				<MenuUser v-if="notAuthPage && isLoggedIn" :user="userStore.getUser">
					<v-divider />
					<v-list>
						<v-list-item v-if="$route.name !== 'Main'" title="На главную" @click="$router.push({ name: 'Main' })">
							<template #prepend>
								<v-icon>
									{{ icons.calendar }}
								</v-icon>
							</template>
						</v-list-item>
						<v-list-item title="Выйти" @click="logout">
							<template #prepend>
								<v-icon>
									{{ icons.logout }}
								</v-icon>
							</template>
						</v-list-item>
					</v-list>
				</MenuUser>
			</template>
		</v-app-bar>
		<DrawerNavigation v-if="notAuthPage && notMainPage && isLoggedIn" v-model="drawer" />
		<template v-if="appStore.isRouterLoading">
			<v-main>
				<v-overlay :model-value="true" :contained="true" class="align-center justify-center">
					<v-progress-circular indeterminate size="64" />
				</v-overlay>
			</v-main>
		</template>
		<template v-else>
			<ErrorSnackbar />
			<v-main>
				<router-view :key="route.fullPath" name="content" />
			</v-main>
		</template>
	</v-app>
</template>

<style lang="scss" scoped>
.app_bar {
	border-color: rgba(var(--v-border-color), var(--v-border-opacity));
	border-style: none none solid none;
	border-width: 1px;
}
</style>
