import type { RouteRecordRaw } from "vue-router"
import { dynamicImportWithRetry } from "@fatso83/retry-dynamic-import"

// !!! dynamicImportWithRetry does not work with import("@/...") syntax - use relative paths instead
const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Main",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewStart.vue"))
		},
		meta: {
			title: "pages.main.title",
			public: false,
			permission: "VIEW_START"
		}
	},
	{
		path: "/turning-shop",
		name: "TurningShop",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewTurningShop.vue"))
		},
		meta: {
			title: "pages.turning_shop.title",
			public: false,
			permission: "VIEW_TURNING_SHOP"
		}
	},
	{
		path: "/unscheduled-maintenance",
		name: "UnscheduledMaintenance",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewUnscheduledMaintenance.vue"))
		},
		meta: {
			title: "pages.unscheduled_maintenance.title",
			public: false,
			permission: "VIEW_UNSCHEDULED_MAINTENANCE"
		}
	},
	{
		path: "/depot-park",
		name: "DepotPark",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewDepotPark.vue"))
		},
		meta: {
			title: "pages.depot_park.title",
			public: false,
			permission: "VIEW_DEPOT_PARK"
		}
	},
	{
		path: "/maintenance-plan",
		name: "MaintenancePlan",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewMaintenancePlan.vue"))
		},
		meta: {
			title: "pages.maintenance_plan.title",
			public: false,
			permission: "VIEW_MAINTENANCE_PLAN"
		}
	},
	{
		path: "/maintenance-fact",
		name: "MaintenanceFact",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewMaintenanceFact.vue"))
		},
		meta: {
			title: "pages.maintenance_fact.title",
			public: false,
			permission: "VIEW_MAINTENANCE_FACT"
		}
	},
	{
		path: "/archive",
		name: "Archive",
		components: {
			content: () => dynamicImportWithRetry(() => import("@/views/ViewArchive.vue"))
		},
		meta: {
			title: "pages.archive.title",
			public: false,
			permission: "VIEW_MAINTENANCE_ARCHIVE"
		}
	},
	{
		path: "/auth",
		name: "Auth",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewAuth.vue"))
		},
		meta: {
			title: "pages.auth.title",
			public: true
		}
	},
	{
		path: "/404",
		name: "NotFound",
		components: {
			content: () => dynamicImportWithRetry(() => import("../views/ViewNotFound.vue"))
		},
		meta: {
			public: true,
			title: "pages.404.title"
		}
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404"
	}
]

export default routes
