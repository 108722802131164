import { createRouter, createWebHistory } from "vue-router"
import routes from "@/router/routes"
import { useAppStore } from "@/store/app"
import { handleUnauthorizedAccess, updatePageTitleAndMeta } from "@/router/functions"
import { captureMessage } from "@sentry/vue"

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { left: 0, top: 0 }
		}
	}
})

router.beforeEach(async (to) => {
	const appStore = useAppStore()
	appStore.routerLoading(true)
	updatePageTitleAndMeta(document, to)
	const route = await handleUnauthorizedAccess(to)
	appStore.routerLoading(false)
	return route
})

router.afterEach((to, from, failure) => {
	if (failure) {
		captureMessage(`router failure: from ${from.fullPath} to ${to.fullPath} - ${failure}`)
	}
})

export default router
