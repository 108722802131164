import ApiSecure from "@/api/api-secure"
import ApiDoc from "@/api/api-doc"
import { response2element } from "@/utils/helpers"

export function getUserInfo(): Promise<any> {
	return ApiSecure.get("profile/self/", { NO_REDIRECT: true, SILENT: true } as never)
}

// 1. TASK

export function getTasksByGroup(params: any): Promise<any> {
	return ApiSecure.get("tasks/grouped/", { params })
}

export function postTask(data: any): Promise<any> {
	return ApiSecure.post("tasks/", data)
}

export function getTask(id: string | number): Promise<any> {
	return ApiSecure.get(`tasks/${id}/`)
}

export function putTask(id: string | number, data: any): Promise<any> {
	return ApiSecure.put(`tasks/${id}/`, data)
}

// 2. SUB TASK

export function postSubTask(data: any): Promise<any> {
	return ApiSecure.post("sub_tasks/", data)
}

export function putSubTask(id: string | number, data: any): Promise<any> {
	return ApiSecure.put(`sub_tasks/${id}/`, data)
}

// 3. TASK GROUP

export function getTaskGroups(params: any): Promise<any> {
	return ApiSecure.get("task_groups/", { params })
}

export function postTaskGroup(data: any): Promise<any> {
	return ApiSecure.post("task_groups/", data)
}

export function getTaskGroup(id: string | number): Promise<any> {
	return ApiSecure.get(`task_groups/${id}/`)
}

export function putTaskGroup(id: string | number, data: any): Promise<any> {
	return ApiSecure.put(`task_groups/${id}/`, data)
}

// 4. MAINTENANCE PLAN

export function getMaintenancePlan(params?: any): Promise<any> {
	return ApiSecure.get(`maintenance_plan/`, { params })
}

export function postMaintenancePlan(data: any): Promise<any> {
	return ApiSecure.post("maintenance_plan/", data)
}

export function putMaintenancePlan(id: string | number, data: any): Promise<any> {
	return ApiSecure.put(`maintenance_plan/${id}/`, data)
}

export function deleteMaintenancePlan(id: string | number): Promise<any> {
	return ApiSecure.delete(`maintenance_plan/${id}/`)
}

export async function getMaintenancePlanPdfFile(data: {
	shift_id: number
	note?: string
}): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.post(`maintenance_plan/download_pdf_file/`, data, {
		responseType: "blob"
	})
	if (response.shouldDownload) {
		return response2element(response)
	}
	return null
}

// 5. SUB TRACK

export function getSubTracks(params?: any): Promise<any> {
	return ApiSecure.get("sub_tracks/", { params })
}

// 6. TRAIN

export function getTrains(params?: any): Promise<any> {
	return ApiSecure.get("trains/", { params })
}

// 7. SHIFT

export function getCurrentShift(params?: any): Promise<any> {
	return ApiSecure.get("shifts/get_by_date/", { params })
}

export function getShifts(params?: any): Promise<any> {
	return ApiSecure.get("shifts/", { params })
}

// 8. INSPECTION

export function getInspections(params?: any): Promise<any> {
	return ApiSecure.get("inspections/", { params })
}

// 9. ARCHIVE

export function getArchive(params?: any): Promise<any> {
	return ApiSecure.get("archive/get_by_date/", { params })
}

export async function getMaintenancePlanArchive(
	params: { document_id: string },
	fileName: string
): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.get("maintenance_plan/download/", {
		params,
		responseType: "blob"
	})
	if (response.shouldDownload) {
		return response2element(response, fileName)
	}
	return null
}
// 10. MAINTENANCE FACT
export function putMaintenanceFact(id: string | number, data: any): Promise<any> {
	return ApiSecure.put(`maintenance_fact/${id}/`, data)
}

export function getMaintenanceFact(params?: any): Promise<any> {
	return ApiSecure.get(`maintenance_fact/`, { params })
}

export async function getMaintenanceFactPdfFile(data: {
	shift_id: number
	note?: string
}): Promise<null | HTMLAnchorElement> {
	const response = await ApiDoc.post(`maintenance_fact/download_pdf_file/`, data, {
		responseType: "blob"
	})
	if (response.shouldDownload) {
		return response2element(response)
	}
	return null
}

export function getMaintenanceFactStaff(id: number): Promise<any> {
	return ApiSecure.get(`staff_types/for_shift/?shift_id=${id}`)
}
export function putMaintenanceFactStaff(data: any): Promise<any> {
	return ApiSecure.post("staff_types/put_for_shift/", data)
}
