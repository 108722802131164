import { defineStore } from "pinia"
import { getUserInfo } from "@/api/requests"
import { captureException, captureMessage } from "@sentry/vue"
import type { UserCredentials, UserType } from "@/types"

interface State {
	credentials: UserCredentials
	user: UserType
}

export const useUserStore = defineStore("userStore", {
	state: (): State => ({
		user: {
			id: 0,
			email: "",
			role: "",
			view_permissions: [
				"VIEW_START",
				"VIEW_TURNING_SHOP",
				"VIEW_UNSCHEDULED_MAINTENANCE",
				"VIEW_DEPOT_PARK",
				"VIEW_MAINTENANCE_PLAN",
				"VIEW_MAINTENANCE_FACT",
				"VIEW_MAINTENANCE_ARCHIVE"
			],
			last_name: "",
			first_name: "",
			middle_name: "",
			position: "",
			department: "",
			timezone: {
				name: "Europe/Moscow",
				value: "+03:00"
			},
			number: ""
		},
		credentials: {
			email: "",
			password: "",
			newPassword: "",
			confirmationPassword: "",
			captcha: "",
			code: ""
		}
	}),

	getters: {
		getCredentialsEmail: (state) => state.credentials.email,
		getCredentialsPassword: (state) => state.credentials.password,
		getCredentialsNewPassword: (state) => state.credentials.newPassword,
		getCredentialsConfirmationPassword: (state) => state.credentials.confirmationPassword,
		getCredentialsCode: (state) => state.credentials.code,
		isLoggedIn: (state) => !!state.user.email,
		getName: (state) => state.user.first_name + " " + state.user.last_name,
		getUser: (state) => state.user,
		getRole: (state) => state.user.role,
		getPermissions: (state) => state.user.view_permissions
	},

	actions: {
		reset() {
			this.$reset()
		},
		async init() {
			try {
				const profile = await getUserInfo()
				this.user.email = profile.email
				this.user.id = profile.id
				this.user.role = profile.role
				this.user.first_name = profile.first_name
				this.user.last_name = profile.last_name
				this.user.middle_name = profile.middle_name
				this.user.position = profile.position
				return true
			} catch (e) {
				captureMessage("auth:store:user: init failed")
				captureException(e)
				return false
			}
		},
		email(payload: string) {
			this.credentials.email = payload ? payload.toLowerCase() : ""
		},
		password(payload: string) {
			this.credentials.password = payload
		},
		newPassword(payload: string) {
			this.credentials.newPassword = payload
		},
		confirmationPassword(payload: string) {
			this.credentials.confirmationPassword = payload
		},
		code(payload: string) {
			this.credentials.code = payload
		}
	}
})
